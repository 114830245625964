import { h, Component } from 'preact'
import style from './style'
import Input from '../input'
import { useState } from 'preact/hooks'

export default ({value, label, suffix, highlight, ...props}) => {
	const [isFocused, setFocus] = useState(false)
	const [isValid, setIsValid] = useState(true)

	const handleInput = e => {
		if (props.readOnly) { return }
		let value = e.target.value
		props.onInput(value)
	}

	const handleClick = () => {
		const el = document.getElementById(props.id)
		el && el.click()
	}

	return (
		<div class={`${style.textInput} ${isFocused && style.isFocused} ${highlight && style.highlight} ${isValid ? '' : style.isInvalid}`}>
			{label && <div class={style.label}>{label}</div>}
			<div class={style.inputWrap}>
				<Input
					{...props}
					value={value}
					onInput={handleInput}
					setFocus={setFocus}
					setIsValid={setIsValid}
					isValid={isValid}
				/>
				{suffix && <div class={style.suffix} onClick={handleClick}>{suffix}</div>}
			</div>
		</div>
	)
}