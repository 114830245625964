import { h, Component } from 'preact'
import { useState, useRef } from 'preact/hooks'
import {useClickOutside, timerToShareURL, copyToClipboard} from '../../helpers/functions'
import style from './style'
import TextField from '../text-field'
import {TooltipButton} from '../buttons'

export default ({children, id}) => {
	const urlWrap = useRef(null)
	const share = useRef(null)
	const [isVisible, setIsVisible] = useState(false)
	const [wasCopied, setWasCopied] = useState(false)
	const [shareUrl, setShareUrl] = useState(timerToShareURL(id))

	useClickOutside(() => {
		setIsVisible(false)
	}, share.current)

	const toggleShare = () => {
		setWasCopied(false)
		setShareUrl(timerToShareURL(id))
		setIsVisible(!isVisible)
		setTimeout(() => {
			urlWrap.current.querySelector('input').select()
		}, 1)
	}

	const handleCopyToClipboard = () => {
		if (copyToClipboard(shareUrl)) {
			setWasCopied(true)
		}
	}

	return <div class={style.share} ref={share}>
		<div onClick={toggleShare}>
			{children}
		</div>
		<div class={`${style.tooltip} ${isVisible ? style.isVisible : null}`} ref={urlWrap}>
			<TextField readOnly value={shareUrl} size="45" />
			{wasCopied
				? <TooltipButton title="Copied" icon="Tick" onClick={handleCopyToClipboard} />
				: <TooltipButton title="Copy to clipboard" icon="Copy" onClick={handleCopyToClipboard} />
			}
		</div>
	</div>
}



