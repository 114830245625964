import { h } from "preact";
import style from "./style.scss";
import TopBar from "../top-bar"; // Assuming TopBar is reusable for page headers
import Footer from "../footer"; // Assuming Footer is reusable
const currentYear = new Date().getFullYear();

const PrivacyPolicy = () => (
  <div class={style.pageContainer}>
    <TopBar title="Privacy Policy" />
    <main class={style.document}>
      <header>
        <h1>Privacy Policy</h1>
        <p>Last Updated: April 2, 2025</p>
      </header>

      <main>
        <section>
          <h2>Introduction</h2>
          <p>
            We are committed to protecting your privacy and ensuring
            transparency regarding the data practices of our application. This
            Privacy Policy outlines how data is handled when you use our
            services.
          </p>
        </section>

        <section>
          <h2>Data Storage</h2>
          <ul>
            <li>
              <strong>Local Storage Only:</strong> All timer data and user
              preferences are stored exclusively within your browser's local
              storage.
            </li>
            <li>
              <strong>No Server Storage:</strong> We do not collect, transmit,
              or store any of your timer data on our servers.
            </li>
            <li>
              <strong>Data Control:</strong> You maintain complete control over
              your data. Clearing your browser cache or local storage will
              remove all timer data.
            </li>
          </ul>
        </section>

        <section>
          <h2>Third-Party Services</h2>
          <p>
            Our application utilizes the following third-party services, each
            with their own data handling practices:
          </p>

          <h3>Google Analytics</h3>
          <p>
            We use Google Analytics to understand how our application is used
            and to improve our service:
          </p>
          <ul>
            <li>
              Collects anonymous usage statistics such as pages visited, time
              spent on the application, and general user interaction patterns.
            </li>
            <li>
              Uses cookies or similar technologies to recognize your device and
              gather information about your interactions.
            </li>
            <li>
              May collect IP addresses, browser information, device type, and
              operating system details.
            </li>
            <li>
              Data collected is processed according to Google's privacy policy:{" "}
              <a href="https://policies.google.com/privacy" target="_blank">
                https://policies.google.com/privacy
              </a>
            </li>
          </ul>

          <h3>Google AdSense</h3>
          <p>
            We use Google AdSense to display advertisements within our
            application:
          </p>
          <ul>
            <li>
              Uses cookies to serve ads based on your previous visits to our
              website or other websites.
            </li>
            <li>
              May use information about your interests to display personalized
              advertisements.
            </li>
            <li>
              Google AdSense partners may also set cookies to collect
              information for ad targeting purposes.
            </li>
            <li>
              For more information on Google AdSense's data practices, please
              visit:{" "}
              <a
                href="https://policies.google.com/technologies/ads"
                target="_blank"
              >
                https://policies.google.com/technologies/ads
              </a>
            </li>
          </ul>

          <h3>Sentry</h3>
          <p>
            We use Sentry for error tracking and application performance
            monitoring:
          </p>
          <ul>
            <li>
              Automatically collects data when errors occur within our
              application.
            </li>
            <li>
              May capture information about the error context, including browser
              information, operating system, and limited information about the
              actions that led to the error.
            </li>
            <li>
              Does not intentionally collect personally identifiable
              information, though some technical information may be included in
              error reports.
            </li>
            <li>
              For more information on Sentry's privacy practices, please visit:{" "}
              <a href="https://sentry.io/privacy/" target="_blank">
                https://sentry.io/privacy/
              </a>
            </li>
          </ul>
        </section>

        <section>
          <h2>Your Choices</h2>
          <p>You have several options to control data collection:</p>
          <ul>
            <li>
              <strong>Ad-blockers:</strong> Use an adblocker to disable ads and
              prevent some tracking.
            </li>
            <li>
              <strong>Browser Settings:</strong> You can configure your browser
              to block or alert you about cookies, or clear cookies and local
              storage periodically.
            </li>
            <li>
              <strong>Analytics Opt-Out:</strong> You can opt out of Google
              Analytics tracking by installing the Google Analytics Opt-out
              Browser Add-on:{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
              >
                https://tools.google.com/dlpage/gaoptout
              </a>
            </li>
            <li>
              <strong>Ad Personalization:</strong> You can manage your ad
              preferences through Google's Ad Settings:{" "}
              <a href="https://adssettings.google.com/" target="_blank">
                https://adssettings.google.com/
              </a>
            </li>
            <li>
              <strong>Do Not Track:</strong> We honor Do Not Track signals when
              technically feasible.
            </li>
          </ul>
        </section>

        <section>
          <h2>Data Security</h2>
          <p>
            Since your timer data is stored locally on your device, the security
            of this data depends on your device's security measures. We
            encourage you to maintain up-to-date security software and use
            secure browser settings.
          </p>
        </section>

        <section>
          <h2>Children's Privacy</h2>
          <p>
            Our service is not directed to children under 13 years of age, and
            we do not knowingly collect personal information from children under
            13.
          </p>
        </section>

        <section>
          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page
            and updating the "Last Updated" date.
          </p>
        </section>
      </main>

      <footer>
        <p>&copy; {currentYear} Timer.fit. All rights reserved.</p>
      </footer>
    </main>
    <Footer />
  </div>
);

export default PrivacyPolicy;
