import { h, Component, Fragment } from "preact";
import { useContext } from "preact/hooks";
import style from "./style";
import Match, { Link } from "preact-router/match";
import { FloatingButton, IconToggle } from "../buttons";
import Logo from "../logo";
import Share from "../share";
import VolumeControl from "../volume-control";
import { TimerAPI, PlayerAPI } from "../api";

export default () => {
  const { id, toggleDarkMode, darkModeActive } = useContext(TimerAPI);
  const reset = useContext(PlayerAPI) && useContext(PlayerAPI).reset;

  return (
    <div class={style.topBar}>
      <header class={style.header}>
        <Link href="/">
          <div class={style.logo}>
            <Logo />
          </div>
          <h1 class={style.heading}>Timer.fit</h1>
        </Link>
      </header>
      <div class={style.links}>
        <IconToggle
          iconOn="Sun"
          iconOff="Moon"
          onClick={toggleDarkMode}
          isActive={darkModeActive}
        />
        <VolumeControl />
        {/*<FloatingButton icon={darkModeActive ? 'Sun' : 'Moon'} onClick={toggleDarkMode} isActive={darkModeActive}></FloatingButton>*/}

        <Match path="/play/:params?">
          {({ matches }) =>
            matches && (
              <Fragment>
                <FloatingButton icon="Spinner" onClick={reset}>
                  Reset
                </FloatingButton>
                <Share id={id}>
                  <FloatingButton icon="Share">Share</FloatingButton>
                </Share>
                <Link
                  class={style.link}
                  activeClassName={style.active}
                  href="/"
                >
                  <FloatingButton icon="Edit">Edit</FloatingButton>
                </Link>
              </Fragment>
            )
          }
        </Match>

        <Match path="/">
          {({ matches }) =>
            matches && (
              <Fragment>
                <Share id={id}>
                  <FloatingButton icon="Share">Share</FloatingButton>
                </Share>
                <Link
                  class={style.link}
                  activeClassName={style.active}
                  href="/play"
                >
                  <FloatingButton primary icon="Play">
                    Start
                  </FloatingButton>
                </Link>
              </Fragment>
            )
          }
        </Match>

        <Match path="/help">
          {({ matches }) =>
            matches && (
              <Fragment>
                <Link
                  class={style.link}
                  activeClassName={style.active}
                  href="/"
                >
                  <FloatingButton icon="Edit">Edit</FloatingButton>
                </Link>
              </Fragment>
            )
          }
        </Match>

        <Match path="/privacy">
          {({ matches }) =>
            matches && (
              <Fragment>
                <Link
                  class={style.link}
                  activeClassName={style.active}
                  href="/"
                >
                  <FloatingButton icon="Edit">Edit</FloatingButton>
                </Link>
              </Fragment>
            )
          }
        </Match>
      </div>
    </div>
  );
};
