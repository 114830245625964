import { h, Component, createRef } from 'preact'
import style from './style'

export default class Input extends Component {
	constructor() {
		super();
		this.input = createRef()
		this.state = {
			chWidth: 0,
		}
	}	
	validate = () => {
		return this.handleSuccess()
	}
	handleSuccess = () => {
		// console.log("Validation Success!")
		this.props.setIsValid(true)
		this.setState({})
		return true
	}
	handleFail = () => {
		// console.log("Validation Failed!")
		this.props.setIsValid(false)
		this.setState({})
		return false
	}
	handleSelect = e => {
		// this.input.current.select()
	}
	handleInput = e => {
		if (this.validate()) {
			this.props.onInput(e)
		}
	}
	resizeWrapper = () => {
		if (!this.props.autosizeNum) { return }

		const width = Math.max(this.props.internalValue.toString().length, this.input.current.value.length)

		this.setState({
			chWidth: width || 1
		})
	}
	componentDidMount() {
		this.validate(this.props.internalValue)
		this.resizeWrapper()
	}
	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.validate(this.props.internalValue)
			this.resizeWrapper()
		}
	}
	handleBlur() {
		this.props.onBlur && this.props.onBlur()
		this.props.setFocus && this.props.setFocus(false)
		this.validate(this.props.internalValue)
	}
	handleFocus() {
		this.props.onFocus && this.props.onFocus()
		this.props.setFocus && this.props.setFocus(true)
	}

	render() {
		const {chWidth} = this.state
		const {value, fullWidth, autosizeText, autosizeNum, clickSelect, setFocus,...rest} = this.props
		return (
			<div
				class={`
					${fullWidth ? style.fullWidth : ''}
					${autosizeText ? style.autosizeText : ''}
				`}
				data-value={autosizeText ? value : ''}>
				<input
					style={{width: autosizeNum && `${chWidth}ch`}}
					class={`${style.input}`}
					type={this.type || 'text'}
					value={this.props.value}
					size={1}
					ref={this.input}
					{...rest}
					onClick={clickSelect ? this.handleSelect : null}
					onInput={this.handleInput}
					onKeyDown={this.handleKeyDown}
					onWheel={this.handleWheel}
					onFocus={() => this.handleFocus()}
					onBlur={() => this.handleBlur()}
				/>
			</div>
		)
	}
}