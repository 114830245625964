import { h, Component, Fragment } from 'preact'
import { useState, useRef, useContext, useEffect } from 'preact/hooks'
import {useClickOutside} from '../../helpers/functions'
import {TimerAPI} from '../../components/api'
import {IconToggle} from '../buttons'
import style from './style'

export default () => {
  const {volume, updateVolume} = useContext(TimerAPI)
  const volumeControl = useRef(null)
  const input = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const [icon, setIcon] = useState("VolumeMedium")
  
  const toggleControl = () => {
    setIsVisible(!isVisible)
  }

  const handleChange = e => {
    updateVolume(e.target.value)
  }

  useEffect(() => {
    let icon = "VolumeMedium"

    if (volume < 0.35) {
      icon = "VolumeLow"
    }
    if (volume === 0) {
      icon = "VolumeMute"
    }
    if (volume > 0.35) {
      icon = "VolumeMedium"
    }
    if (volume > 0.85) {
      icon = "VolumeHigh"
    }

    setIcon(icon)
  }, [volume])
  
  useClickOutside(() => {
		setIsVisible(false)
	}, volumeControl.current)

  return <div class={style.wrapper} ref={volumeControl}>
      <div class={style.icon}>
        <IconToggle iconOn={icon} iconOff={icon} onClick={toggleControl} isActive={isVisible} />
      </div>
      <div class={`${style.control} ${isVisible ? style.isVisible : null}`}>
        <input type="range" value={volume * 100} onInput={handleChange} />
      </div>
    </div>
}