import { h } from "preact";
import { Link } from "preact-router/match"; // Import Link for internal navigation
import style from "./style";
import { SlightlySmilingFace, SmilingEyes } from "../../assets/icons/svg-icons";

export default () => {
  return (
    <footer class={style.footer}>
      <ul class={style.list}>
        {/* <li class={style.listItem}>made by kpatient</li>
        <li class={style.listItem}>
          <a
            class={style.listLink}
            href="mailto:kpatient@gmail.com?subject=Interval Timer Feedback"
            target="_blank"
            rel="noreferrer"
          >
            give feedback
          </a>
        </li>
        <li class={style.listItem}>
          <a
            class={style.listLink}
            href="https://ko-fi.com/kpatient"
            target="_blank"
            rel="noreferrer"
          >
            buy me a coffee <SlightlySmilingFace />
            <SmilingEyes />
          </a>
        </li> */}
        <li class={style.listItem}>
          <Link class={style.listLink} href="/help">
            Help
          </Link>
        </li>
        <li class={style.listItem}>
          <Link class={style.listLink} href="/privacy">
            Privacy Policy
          </Link>
        </li>
      </ul>
    </footer>
  );
};
