import { h } from "preact";
import style from "./style.scss"; // We'll create this next
import TopBar from "../top-bar";
import Footer from "../footer";

const HowTo = () => (
  <div class={style.pageContainer}>
    <TopBar title="Help Guide" />
    <main class={style.document}>
      <h1>Help Guide</h1>

      <h2>Terminology</h2>
      <p>
        <strong>Intervals</strong> - Think of this as the amount of times you
        want to do an exercise within a set. Could also be considered as rounds.
      </p>
      <p>
        <strong>Set</strong> - A collection of one type of intervals. For
        example you might have a set for pushups and another set for squats.
      </p>
      <p>
        <strong>Warm Up Time</strong> - The amount of time before the timer
        starts. This will give you time to get ready for the timer or next set
        to start. Can be applied to any set.
      </p>
      <p>
        <strong>Rest Time</strong> - The amount of time between each interval.
        So if you're doing 5 intervals of 30 second squats with 5 seconds rest.
        You would get 5 seconds rest between each squat interval.
      </p>

      <h2>Keyboard Shortcuts</h2>
      <p>The following hotkeys are available while using Timer.fit:</p>
      <ul>
        <li>
          <span class="hotkey">Space Bar</span>: Play or pause the current timer
        </li>
        <li>
          <span class="hotkey">Left Arrow</span>: Go back to the previous
          interval
        </li>
        <li>
          <span class="hotkey">Right Arrow</span>: Skip to the next interval
        </li>
      </ul>
      <p>
        These shortcuts provide convenient control without needing to use the
        mouse, especially useful during workouts.
      </p>

      <h2>Sharing Your Timer</h2>
      <p>Timer.fit allows you to share your custom timers with others:</p>
      <ol>
        <li>After configuring your timer, click the "Share" button.</li>
        <li>
          The application will generate a URL that encodes all your timer
          settings.
        </li>
        <li>
          Copy this URL and share it via email, messaging apps, or social media.
        </li>
      </ol>

      <p>
        <strong>Note</strong>: The URL contains all timer configuration
        parameters in the query string (everything after the "?" in the
        address). Here's an example with 5 x 30 second intervals and 4 second
        rest and warm up over 2 sets:
      </p>
      <div class="url-example">
        <a href="https://timer.fit/play?0=Glutes,30,5,4,4&1=Quads,30,5,4,4">
          https://timer.fit/play?0=Glutes,30,5,4,4&1=Quads,30,5,4,4
        </a>
      </div>

      <p>
        This structure makes it easy to save your favorite timers in a text
        document or spreadsheet for future reference.
      </p>

      <h2>Data Storage Information</h2>
      <p>
        Timer.fit stores all your timer configurations in your browser's
        localStorage. This means:
      </p>
      <ul>
        <li>
          Your timers will persist between sessions on the same device and
          browser.
        </li>
        <li>
          If you clear your browser data, cache, or localStorage, your saved
          timers will be permanently deleted.
        </li>
        <li>
          For long-term storage, we recommend saving the share URLs of your
          favorite timers in a separate location.
        </li>
        <li>
          Timer configurations are not synchronized between different devices or
          browsers.
        </li>
      </ul>

      <p>
        For important timer configurations, consider using the sharing feature
        and storing the URLs externally to ensure you don't lose your customized
        timers.
      </p>
    </main>
    <Footer />
  </div>
);

export default HowTo;
